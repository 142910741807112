// variables
$sm: 576px; // refer Bootstrap v4.0
$gutter: 30px;
$gutter-half: $gutter * .5;


$font_size_base: 14px;
$font_size_sm: 12px;
$font_size_xs: 11px;
$font_size_lg: 16px;
$font_size_xl: 18px;
$font_size_xxl: 24px;

$blue_light_1: #5E73A7;
$blue_mid_1: #3c5996;

$brown_dark_1: #402000;
$brown_dark_2: #3f1f00;

$gray_pale_1: #f0f2f1;
$gray_mid_1: #9b9d9f;
$gray_dark_1: #333;

$yellow_brilliant_1: #FFDD1C;
$yellow_pale_1: #ffed8e;
$yellow_pale_2: #fff5c2;
$yellow_mid_1: #ffda00;

$orange_brilliant_1: #fcb030;
$orange_brilliant_2: #ea5504;

$red_brilliant_1: #F07E6D;

$red_dark_1: #913F33;



$skyblue_brilliant_1: #8dc1e3;
$skyblue_brilliant_2: #8ebde4;

$yellow_green_right_1: #e3f1d0;
$yellow_green_brilliant_1: #8fc740;
$yellow_green_brilliant_2: #bed730;
$yellow_green_brilliant_3: #93c641;

$navlist_padding_sm: 1em .5em .8em;

$nav_bg_color_1: $yellow_brilliant_1;
$nav_bg_color_2: $yellow_pale_1;
$nav_hov_bg_color_1: $orange_brilliant_1;
$nav_border_color_1: $yellow_mid_1;
$nav_border_color_2: $yellow_pale_2;

$spacer_1: 1em;
$spacer_2: 1.5em;
$spacer_3: 3em;
$spacer_4: 5em;
$spacer_5: 7.5em;

$border_radius_h2: 20px;
$border_radius_h3: 10px;
$border_radius: 10px;

$gutter-width: 15px;

$contents_wrap_img_max_width: 300px;
