/*--------------------
.header_nav
--------------------*/

.btn_mobile_nav {
  display: none;
  position: absolute;
  top: 20px;
  right: $gutter-half;
  overflow: hidden;
  margin: 0;
  text-indent: -9999px;
  cursor: pointer;
  span,
  span:before,
  span:after {
    position: relative;
    display: block;
    float: right;
    margin: 1em 0;
    width: 28px;
    height: 3px;
    border-radius: 1px;
    background: $yellow_green_brilliant_1;
    transition: all 0.5s;

    backface-visibility: hidden;
  }
  span:before,
  span:after {
    position: absolute;
    content: "";
  }
  span:before {
    top: -0.5em;
  }
  span:after {
    bottom: -0.5em;
  }
  &.selected {
    span {
      transform: rotate(45deg);
    }
    span:before {
      top: -1em;
      opacity: 0;
      transform: rotate(0deg);
    }
    span:after {
      bottom: -1em;
      transform: rotate(-90deg);
    }
  }
  & + .nav {
    display: none;
  }
  @media only screen and (max-width: $sm) {
    display: block;
  }
}

.header_nav {
  margin: -28px 0 25px;
  height: 52px;
  background: url(img/nav_bg.gif) repeat-x bottom;
  display: block;
  @media only screen and (max-width: $sm) {
    display: none;
    margin: 0 0 1em;
    height: auto;
    background: none;
  }
}

.nav_tab {
  background: url(img/nav_tab_bg.gif) no-repeat left bottom;
  width: 992px;
  height: 32px;
  margin: 0 auto;
  padding-top: 20px;
  position: relative;
  z-index: 10;
  @media only screen and (max-width: $sm) {
    background: none;
    margin: 0;
    padding: 0;
    width: auto;
    height: auto;
  }
}

.nab_tab_list,
.nab_tab_list * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nab_tab_list {
  margin: 0 0 0 10px;
  width: 722px;
  line-height: 1;
  @media only screen and (max-width: $sm) {
    width: auto;
    margin: 0;
  }
  li {
    position: relative;
    float: left;
    &:hover ul {
      left: 0;
      z-index: 99;
      display: block;
    }
    @media only screen and (max-width: $sm) {
      border-bottom: 1px solid #fff;
      float: none;
      &:hover ul {
        top: auto;
        display: none;
      }
    }
  }
  a {
    position: relative;
    z-index: 2;
    display: block;
    padding: 12px 10px 9px 33px;
    text-decoration: none;
    @media only screen and (max-width: $sm) {
      padding: $navlist_padding_sm;
    }
    &.nav_home {
      width: 63px;
      background: url(img/nav.png) no-repeat 0 0;
      @media only screen and (max-width: $sm) {
        width: auto;
        background: none;
        color: $gray_dark_1;
        &:hover {
          background: $nav_hov_bg_color_1;
          text-decoration: none;
        }
      }
    }
  }

  span {
    &.nav_about {
      width: 183px;
      background: url(img/nav.png) no-repeat -63px 0;
      &:hover {
        background-position: -63px -40px;
      }
    }
    &.nav_join {
      width: 146px;
      background: url(img/nav.png) no-repeat -246px 0;
      &:hover {
        background-position: -246px -40px;
      }
    }
    &.nav_program {
      width: 129px;
      background: url(img/nav.png) no-repeat -392px 0;
      &:hover {
        background-position: -392px -40px;
      }
    }
    &.nav_cultureschool {
      width: 201px;
      background: url(img/nav.png) no-repeat -521px 0;
      &:hover {
        background-position: -521px -40px;
      }
    }
    @media only screen and (max-width: $sm) {
      &.nav_about,
      &.nav_join,
      &.nav_program,
      &.nav_cultureschool {
        width: auto;
        background: none;
      }
    }
  }
  a.nav_home,
  span.nav_about,
  span.nav_join,
  span.nav_program,
  span.nav_cultureschool {
    display: block;
    overflow: hidden;
    padding: 0;
    height: 32px;
    border: none;
    text-indent: -9999px;
    @media only screen and (max-width: $sm) {
      display: flex;
      padding: $navlist_padding_sm;
      height: auto;
      background: $nav_bg_color_1;
      text-indent: inherit;

      align-items: center;
      justify-content: center;
    }
  }

  span.nav_about,
  span.nav_join,
  span.nav_program,
  span.nav_cultureschool {
    @media only screen and (max-width: $sm) {
      &:after {
        position: relative;
        top: 0.25em;
        right: -0.4em;
        display: block;
        width: 0;
        height: 0;
        border-width: 0.5em;
        border-style: solid;
        border-color: $brown_dark_1 transparent transparent transparent;
        content: "";
        transition: transform 0.2s ease, top 0.2s ease;
        transform: rotate(0deg);
      }
      &.visible:after {
        top: -0.25em;
        transform: rotate(-180deg);
      }
    }
  }

  ul {
    position: absolute;
    top: 32px;
    display: none;
    width: 197px;
    @media only screen and (max-width: $sm) {
      // display: block;
      position: relative;
      width: auto;
      top: 0;
    }

    li {
      width: 197px;
      z-index: 1;
      border: solid 1px #fff;
      border-bottom: none;
      @media only screen and (max-width: $sm) {
        width: auto;
        border-top: 1px solid $nav_border_color_1;
      }
      a,
      a:visited {
        overflow: hidden;
        background: $nav_bg_color_1 url(img/nav_sf_arrow.gif) no-repeat 10px
          11px;
        color: $gray_dark_1;
        line-height: 1.2;
        @media only screen and (max-width: $sm) {
          background: $nav_bg_color_2;
          text-align: center;
        }
      }
      &:first-child,
      &:first-child a,
      &:first-child a:hover {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
      &:last-child,
      &:last-child a,
      &:last-child a:hover {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
      @media only screen and (max-width: $sm) {
        &:first-child,
        &:first-child a,
        &:first-child a:hover,
        &:last-child,
        &:last-child a,
        &:last-child a:hover {
          border-radius: 0;
        }
      }
      &.navlist_sub {
        border-top: none;
        border-right: solid 1px #fff;
        border-bottom: none;
        border-left: solid 1px #fff;
        a,
        a:visited {
          background: url(img/nav_sf_arrow_sub.gif) no-repeat scroll 27px 11px
            $nav_bg_color_1;
          color: $gray_dark_1;
          line-height: 1.2;
          padding: 9px 10px 8px 40px;
        }
        a:hover,
        a.select {
          background: url(img/nav_sf_arrow_sub.gif) no-repeat scroll 27px 11px
            $nav_hov_bg_color_1;
          text-decoration: none;
        }
        @media only screen and (max-width: $sm) {
          a,
          a:visited,
          a:hover,
          a.select {
            background-image: none;
            border-top: 1px dashed $nav_border_color_1;
            padding: $navlist_padding_sm;
          }
          a,
          a:visited {
            background: $yellow_pale_2;
          }
          a.select,
          a:hover {
            background: $nav_hov_bg_color_1;
          }
        }
      }
    }
  }
  li.navlist:hover,
  ul .navlist {
    a:focus,
    a:hover,
    a:active,
    a.select,
    .current-cat a {
      outline: 0;
      background: $nav_hov_bg_color_1 url(img/nav_sf_arrow.gif) no-repeat -376px
        11px;
      color: $gray_dark_1;
      text-decoration: none;
      @media only screen and (max-width: $sm) {
        background-image: none;
      }
    }
  }
}

.home {
  .nab_tab_list {
    a.nav_home,
    a.nav_home:visited,
    a.nav_home:hover {
      background-position: 0 -80px;
      text-decoration: none;
    }
  }

  @media only screen and (max-width: $sm) {
    a.nav_home,
    a.nav_home:hover {
      background: $nav_hov_bg_color_1;
      text-decoration: none;
    }
  }
}

.about .nab_tab_list span.nav_about {
  background-position: -63px -80px;
  @media only screen and (max-width: $sm) {
    background: $nav_hov_bg_color_1;
  }
}

.join .nab_tab_list span.nav_join {
  background-position: -246px -80px;
  @media only screen and (max-width: $sm) {
    background: $nav_hov_bg_color_1;
  }
}

.program .nab_tab_list span.nav_program {
  background-position: -392px -80px;
  @media only screen and (max-width: $sm) {
    background: $nav_hov_bg_color_1;
  }
}

.cultureschool,
.communication-text,
.product {
  .nab_tab_list span.nav_cultureschool {
    background-position: -521px -80px;
    @media only screen and (max-width: $sm) {
      background: $nav_hov_bg_color_1;
    }
  }
}
