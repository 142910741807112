@charset "utf-8";
/*!
Theme Name: IogiMinna
Theme URI: https://www.minna-gakkou.jp/
Description: Theme for WordPress
Version: 1.26
Author: IogiMinna
Author URI: https://www.minna-gakkou.jp/
*/

@import "_variables";

/*--------------------
for HTML5
--------------------*/
article,
aside,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}
/*--------------------
default
--------------------*/
body {
  background: none repeat scroll 0 0 #ffffff;
  color: $gray_dark_1;
  font-family: "メイリオ", Meiryo, "ヒラギノ角ゴ Pro W3",
    "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", arial, Osaka,
    sans-serif;
  font-size: $font_size_base;
  line-height: 1.8;
  margin: 0;
  padding: 0;
  @media only screen and (max-width: $sm) {
    font-size: $font_size_lg;
  }
}

img {
  border: 0;
  margin: 0;
  padding: 0;
}

a img {
  border: none;
}

.left {
  float: left;
}
.right {
  float: right;
}
.red {
  color: $red_brilliant_1;
}
.txt_right {
  text-align: right;
}
.alignleft {
  float: left;
}
.alignright {
  float: right;
}
blockquote {
  font-family: Georgia, "Bitstream Charter", serif;
  font-style: italic;
  font-weight: normal;
}
.contents blockquote p {
  width: auto;
  margin: 0;
}

@import "_header";

@import "_header_nav";

/*--------------------
contents common
--------------------*/
.wrap {
  width: 966px;
  margin: 0 auto 30px;
  padding: 0 6px 0 0;
  @media only screen and (max-width: $sm) {
    width: auto;
    padding: 0 6px 0;
  }
}
.contents_wrap {
  float: left;
  width: 694px;
  @media only screen and (max-width: $sm) {
    float: none;
    margin-bottom: 2em;
    width: auto;
  }
  img {
    @media only screen and (max-width: $sm) {
      margin: 0 auto 1em !important;
      max-width: 100%;
      height: auto;
      display: block;
    }
  }
}

.contents {
  width: 682px;
  margin: 0 6px;
  @media only screen and (max-width: $sm) {
    width: auto;
  }
}

.contents p {
  width: 646px;
  margin: 0 auto 25px;
}

.contents p + .h3,
.contents p + h3,
.contents p + div {
  margin-top: 50px;
}

.contents table {
  width: auto;
  margin: 0 18px 25px;
}

a.dl_btn_application,
a.dl_btn_application:visited,
a.dl_btn_terms,
a.dl_btn_terms:visited,
a.dl_btn_disaster_prevention,
a.dl_btn_disaster_prevention:visited,
a.dl_btn_program,
a.dl_btn_program:visited {
  text-indent: -9999px;
  line-height: 1;
  height: 50px;
  display: block;
  overflow: hidden;
  margin: 8px 0;
  padding: 0;
}
a.dl_btn_application,
a.dl_btn_application:visited {
  background: url(img/downloard_btn.gif) no-repeat left top;
  width: 273px;
  margin-bottom: 0;
}
a.dl_btn_application:hover {
  background-position: left bottom;
}
a.dl_btn_terms,
a.dl_btn_terms:visited {
  background: url(img/downloard_btn.gif) no-repeat -273px top;
  width: 258px;
  margin: 0 auto;
}
a.dl_btn_terms:hover {
  background-position: -273px bottom;
}
a.dl_btn_disaster_prevention,
a.dl_btn_disaster_prevention:visited {
  background: url(img/downloard_btn.gif) no-repeat -529px top;
  width: 370px;
  margin: 0 auto;
}
a.dl_btn_disaster_prevention:hover {
  background-position: -529px bottom;
}
a.dl_btn_program,
a.dl_btn_program:visited {
  background: url(img/downloard_btn.gif) no-repeat -897px top;
  width: 286px;
  margin: 10px 0;
}
a.dl_btn_program:hover {
  background-position: -897px bottom;
}

/*--------------------
prevnext
--------------------*/
.contents_wrap .prevnext {
  width: 220px !important;
  margin: 50px auto 0;
  overflow: hidden;
  clear: both;
  .btn_prev,
  .btn_next {
    background: url(img/arrow_underline.gif) repeat-x bottom;
    margin-bottom: 15px !important;
    padding-bottom: 6px;
  }
  .btn_prev {
    float: left !important;
    a,
    a:visited {
      background: url(img/arrow_left.gif) no-repeat left;
      line-height: 1;
      padding-top: 3px;
      padding-left: 20px;
      padding-bottom: 1px;
      display: block;
    }
    a:hover {
      background: #e6f0f9 url(img/arrow_left.gif) no-repeat left;
      color: $blue_light_1;
      text-decoration: none;
    }
  }
  .btn_next {
    float: right !important;
    a,
    a:visited {
      background: url(img/arrow_right.gif) no-repeat right;
      line-height: 1;
      text-align: right;
      padding-top: 3px;
      padding-right: 20px;
      padding-bottom: 1px;
      display: block;
    }
    a:hover {
      background: #e6f0f9 url(img/arrow_right.gif) no-repeat right;
      color: $blue_light_1;
      text-decoration: none;
    }
  }

  .btn_list {
    background: url(img/arrow_underline.gif) repeat-x bottom;
    width: 110px !important;
    clear: both;
    margin: 0 auto;
    padding-bottom: 6px;
    a,
    a:visited {
      background: url(img/arrow_leftmore.gif) no-repeat left;
      line-height: 1;
      padding-top: 3px;
      padding-left: 30px;
      padding-bottom: 1px;
      display: block;
    }
    a:hover {
      background: #e6f0f9 url(img/arrow_leftmore.gif) no-repeat left;
      color: $blue_light_1;
      text-decoration: none;
    }
  }
}

/*--------------------
/prevnext
--------------------*/
.contents .thumb {
  overflow: hidden;
}
.contents .thumb img,
.communication_text img.size-thumbnail,
.communication_text img.attachment-thumbnail {
  margin: 0 20px 20px 0;
  float: left;
  display: block;
}

/*--------------------
contents
--------------------*/
/*--concept--*/
.promise5 {
  margin-bottom: 50px;
}

/*--company,access--*/

.contents img.staff_tab {
  width: 90px;
  height: 24px;
  display: block;
  float: none;
  margin: 0;
}
.staff {
  line-height: 1.4;
  width: 646px;
  margin: 0 auto 10px;
  .staff_tab {
    @media only screen and (max-width: $sm) {
      margin: 2em 0 0 !important;
    }
  }
  .box {
    border: solid 1px #93c641;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 15px 0 0;
    overflow: hidden;
    p {
      width: 510px;
      margin: 0 18px 15px 0 !important;
      float: right;
      @media only screen and (max-width: $sm) {
        clear: none;
      }
    }
    img,
    hr + p {
      width: 82px;
      height: 82px;
      display: block;
      margin: 0 10px 15px 18px;
      float: left;
    }
    img {
      @media only screen and (max-width: $sm) {
        margin: 0 1em 1em 0 !important;
      }
    }
    hr {
      clear: both;
      margin: 0 auto 15px;
      width: 610px;
      height: 1px;
      border-top: dashed 1px #93c641;
      border-right: 0;
      border-bottom: 0;
      border-left: 0;

      @media only screen and (max-width: $sm) {
        margin: 1em 0;
        width: auto;
      }
      &:first-child {
        display: none;
      }
      & + p img {
        margin: 0;
      }
    }
  }
}

/*--lineup--*/
.contents .lineupcourse img {
  margin: 0;
  display: block;
}

.lineupcourse .summary_bg {
  background: url(img/course_summary_bg.gif) no-repeat top;
  padding: 10px 0 40px;
}

.lineupcourse .summary {
  line-height: 1.6;
  overflow: hidden;
  margin-left: 0px;
  padding-bottom: 41px;
  @media only screen and (max-width: $sm) {
    padding-bottom: 0;
    margin-bottom: 0 !important;
  }
  .inner {
    overflow: hidden;
    margin-left: 0px;
    padding-left: 1px;
  }
  .regular,
  .program,
  .spot {
    width: 204px;
    float: left;
    margin-left: 17px;
  }
  .regular {
    border: solid 1px #8ec741;
    border-radius: 12px;
  }
  .program {
    border: solid 1px #f39221;
    border-radius: 12px;
  }
  .spot {
    border: solid 1px $gray_mid_1;
    border-radius: 12px;
  }
  a.btn {
    text-indent: -9999px;
    width: 166px;
    height: 24px;
    display: block;
    overflow: hidden;
    margin: 0 auto 10px;
  }
  .regular a.btn {
    background: url(img/course_summary_btn.gif) no-repeat 0 0;
  }
  .program a.btn {
    background: url(img/course_summary_btn.gif) no-repeat -166px 0;
  }
  .spot a.btn {
    background: url(img/course_summary_btn.gif) no-repeat -332px 0;
  }
  .regular a.btn:hover {
    background-position: 0 -24px;
  }
  .program a.btn:hover {
    background-position: -166px -24px;
  }
  .spot a.btn:hover {
    background-position: -332px -24px;
  }
}

/*--regularcourse--*/
.regularcourse .summary {
  background: $yellow_green_right_1 url(img/cours_bg_snap_bottom.jpg) no-repeat
    0 bottom;
  width: 646px;
  margin: 0 auto 30px;
  padding-bottom: 13px;
  overflow: hidden;
  @media only screen and (max-width: $sm) {
    background: none;
    background-color: $yellow_green_right_1;
    border-radius: $border_radius;
    padding-bottom: 0;
  }
  .inner {
    background: url(img/cours_bg_snap.jpg) no-repeat 0 0;
    width: 646px;
    margin: 0 !important;
    padding: 0;
    @media only screen and (max-width: $sm) {
      background: none;
    }
  }
}

/*--programcourse--*/
.programcourse .summary {
  background: url(img/cours_bg_snap_bottom.jpg) no-repeat -646px bottom;
  width: 646px;
  margin: 0 auto 30px;
  padding-bottom: 13px;
  overflow: hidden;
}
.programcourse .summary .inner {
  background: url(img/cours_bg_snap.jpg) no-repeat -646px 0;
  width: 646px;
  height: 150px;
  margin: 0;
  padding: 0;
}

/*--specialcourse--*/
.specialcourse .summary {
  background: url(img/cours_bg_snap_bottom.jpg) no-repeat -1938px bottom;
  width: 646px;
  margin: 0 auto 30px;
  padding-bottom: 13px;
  overflow: hidden;
}
.specialcourse .summary .inner {
  background: url(img/cours_bg_snap.jpg) no-repeat -1938px 0;
  width: 646px;
  height: 140px;
  margin: 0;
  padding: 0;
}
.specialcourse .summary .txt {
  line-height: 1.6;
  width: 312px;
  padding: 20px 0 18px 20px;
  float: left;
}

/*--experience--*/
.experience .summary {
  background: url(img/cours_bg_snap_bottom.jpg) no-repeat -2584px bottom;
  width: 646px;
  margin: 0 auto 30px;
  padding-bottom: 13px;
  overflow: hidden;
}
.experience .summary .inner {
  background: url(img/cours_bg_snap.jpg) no-repeat -2584px 0;
  width: 646px;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.summary .snap {
  float: right;
}
/*--option--*/
.join .option {
  clear: both;
}
.join .regularcourse .option,
.join .programcourse .option {
  width: 334px;
  float: left;
}

/*--schedule--*/
.join .regularcourse .timeline,
.join .programcourse .timeline {
  width: 334px;
  float: right;
}

.price_note {
  border-top: solid 1px $yellow_green_brilliant_1;
  font-size: $font_size_sm;
  padding: 10px 20px;
  clear: both;
}
/*--spotcourse--*/
.join .spotcourse .option {
  width: 682px;
  margin: 0 auto;
}

/*--flow--*/

.flow .step1 {
  background: url(img/flow_step1_illust.gif) no-repeat 480px top;
  @media only screen and (max-width: $sm) {
    background-position: center bottom 30px;
  }
}
.flow .step2 {
  background: url(img/flow_step2_illust.gif) no-repeat 480px top;
  margin-bottom: 10px;
  @media only screen and (max-width: $sm) {
    background-position: center bottom 30px;
  }
}
.flow .step3 {
  background: url(img/flow_step3_illust.gif) no-repeat 480px top;
  @media only screen and (max-width: $sm) {
    background-position: center bottom 30px;
  }
}
.flow .step1 h4 {
  background: url(img/flow_bullet.gif) no-repeat left top;
}
.flow .step2 h4 {
  background: url(img/flow_bullet.gif) no-repeat left -52px;
}
.flow .step3 h4 {
  background: url(img/flow_bullet.gif) no-repeat left -104px;
}
.flow .step3 p {
  background: none;
}
.flow p span.note {
  display: block;
  margin-top: 10px;
}
/*--terms--*/

.program_list {
  background: url(img/program_list_bg.jpg) no-repeat bottom;
  margin-bottom: 30px;
  padding-bottom: 30px;
  overflow: hidden;
}
.program_list img {
  margin: 0;
  display: block;
}
.program_list .daily {
  width: 126px;
  float: left;
  margin: 0 4px 20px 0;
}
.program_list .daily p {
  background: #ffffff;
  border-left: solid 1px #ea5550;
  border-right: solid 1px #ea5550;
  line-height: 1.6;
  width: 114px;
  margin: 0;
  padding: 5px;
}
.program_list .daily a,
.program_list .daily a:visited {
  background: url(img/programlist_more_btn.gif) no-repeat top;
  text-indent: -9999px;
  width: 126px;
  height: 44px;
  display: block;
  overflow: hidden;
}
.program_list .daily a:hover {
  background-position: bottom;
}
.program_list .note {
  text-align: center;
  line-height: 1.6;
  width: 100%;
  margin: 0;
  clear: both;
}
.program_pdf {
  overflow: hidden;
  margin: 50px 0;
}
.program_pdf img {
  width: 227px;
  float: left;
  margin-right: 30px;
}
.program_description {
  clear: both;
}
.program_description .day_btn {
  margin: 0 0 5px 18px;
}
.day_btn {
  width: 252px;
  height: 36px;
  margin: 0 0 5px 18px;
  overflow: hidden;
}

.scenery,
.teacher {
  width: 646px;
  margin: 0 auto 10px;
}
.contents p + div.scenery {
  margin: -10px auto 25px;
  display: none;
}
.contents img.scenery_tab,
.contents img.teacher_tab {
  width: 90px;
  height: 24px;
  display: block;
  float: none;
  margin: 0;
}
.scenery .box,
.teacher .box {
  border: solid 1px #ea5752;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 15px 0;
  overflow: hidden;
}
.scenery .box img {
  float: left;
  margin: 0 2px 0 18px;
  display: block;
}
.scenery a.more_btn,
.scenery a.more_btn:visited {
  background: url(img/more_btn2.gif) no-repeat top;
  text-indent: -9999px;
  width: 122px;
  height: 41px;
  display: block;
  overflow: hidden;
  float: left;
  margin: 34px 0 0 14px;
}
.scenery a.more_btn:hover {
  background-position: bottom;
}
.teacher {
  line-height: 1.6;
}
.teacher .box {
  padding-bottom: 0;
}
.teacher img,
.teacher hr + p,
.teacher .box p:first-child {
  width: 82px;
  height: 82px;
  display: block;
  margin: 0 10px 15px 18px;
  float: left;
}
.teacher p img {
  width: auto;
  height: auto;
  margin: 0;
  float: none;
  display: inline;
}
.teacher hr + p img {
  margin: 0;
}

.teacher hr {
  border-top: dashed 1px #ea5350;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  width: 610px;
  height: 1px;
  clear: both;
  margin: 0 auto 15px;
}
/*--program2015--*/
.prg2015 {
  background: url(img/program2015_rainbow.png) no-repeat -8em -3.5em;
  padding: 3em 3em 2.7em;
  overflow: hidden;
  position: relative;
  @media only screen and (max-width: $sm) {
    padding: $gutter-width $gutter-width 7em;
  }
  &:before {
    background: #8cc63f;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    left: 0;
    z-index: -2;
  }
  &:after {
    background: url(img/program2015_banner.png) no-repeat 107% 90%;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    left: 0;
    z-index: -1;
    @media only screen and (max-width: $sm) {
      background-position: right -9px bottom 20px;
    }
  }
  p {
    width: auto;
    &.title {
      margin: 0;
    }
    &.article02 {
      width: 70%;
      margin: 0;
    }
  }
}
.prg2015 {
  .subtitle {
    clear: both;
    color: #fff;
    font-size: $font_size_lg;
    font-weight: bold;
    text-align: center;
  }
  .article01 {
    color: #fff;
  }

  .article02 strong {
    color: #fff;
  }

  & + .menu_btn {
    background: #fdefde;
    border-radius: 0.5em;
    margin: 3em 0px 0px;
    padding: 2.6em 3em 2em;
    position: relative;
    overflow: visible;
    height: 142px;
    @media only screen and (max-width: $sm) {
      height: auto;
      overflow: hidden;
      padding: $gutter-width;
    }
  }
}

.prg2015 {
  & + .menu_btn {
    h3 {
      background: $gray_dark_1;
      border-radius: 2em;
      color: #fff;
      left: 0px;
      line-height: 1;
      margin: 0px auto;
      padding: 0.7em 0.7em 0.5em;
      position: absolute;
      right: 0px;
      text-align: center;
      top: -1em;
      width: 7em;
      @media only screen and (max-width: $sm) {
        position: relative;
        right: auto;
        top: auto;
        margin-bottom: $gutter-width;
      }
    }
    ul {
      width: 49%;
      float: left;
      @media only screen and (max-width: $sm) {
        float: none;
        width: auto;
      }
    }
    ul:last-child {
      margin-left: 2%;
      @media only screen and (max-width: $sm) {
        margin-left: 0;
      }
    }
    li a {
      background: #528d39;
      border-radius: 0.5em;
      color: #fff;
      display: block;
      line-height: 1;
      margin-bottom: 0.5em;
      padding: 1em 1em 1em 2.5em;
      position: relative;
    }
    li a:before {
      border-color: #fff transparent transparent;
      border-style: solid;
      border-width: 0.7em 0.5em;
      content: "";
      display: block;
      height: 0px;
      left: 1em;
      position: absolute;
      top: 1.2em;
      width: 0px;
    }
    li a:hover {
      text-decoration: none;
      opacity: 0.8;
    }
    li a.ability {
      background: #faa74a;
    }
    li a.intelligence {
      background: #6c8cc7;
    }
    li a.expressive {
      background: #67c18c;
    }
    li a.sports {
      background: #f15b4e;
    }
    li a.social {
      background: #a6ce39;
    }
    li a.sentiment {
      background: #8a73b4;
    }
    & + h3 {
      border-radius: 0.5em;
      position: relative;
      width: 627px;
      padding: 13px 20px 12px 35px;
      margin-bottom: 10px;
      margin-top: 30px;
      line-height: 1;
      color: #fff;
      @media only screen and (max-width: $sm) {
        width: auto;
      }
    }
  }
}

h3#ability {
  background: #faa74a;
}
h3#intelligence {
  background: #6c8cc7;
}
h3#expressive {
  background: #67c18c;
}
h3#sports {
  background: #f15b4e;
}
h3#social {
  background: #a6ce39;
}
h3#sentiment {
  background: #8a73b4;
}

.prg2015 + .menu_btn + h3:before,
.prg2015_photo + h3:before {
  border-style: solid;
  border-radius: 1em;
  border-color: #fff;
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  left: 0.7em;
  border-width: 0.5em;
  top: 30%;
}
#ability + .prg2015_photo ul {
  background: #faa74a;
}
#ability + .prg2015_photo ul li:first-child {
  background: #faa74a;
}
#intelligence + .prg2015_photo ul {
  background: #6c8cc7;
}
#intelligence + .prg2015_photo ul li:first-child {
  background: #6c8cc7;
}
#expressive + .prg2015_photo ul {
  background: #67c18c;
}
#expressive + .prg2015_photo ul li:first-child {
  background: #67c18c;
}
#sports + .prg2015_photo ul {
  background: #f15b4e;
}
#sports + .prg2015_photo ul li:first-child {
  background: #f15b4e;
}
#social + .prg2015_photo ul {
  background: #a6ce39;
}
#social + .prg2015_photo ul li:first-child {
  background: #a6ce39;
}
#sentiment + .prg2015_photo ul {
  background: #8a73b4;
}
#sentiment + .prg2015_photo ul li:first-child {
  background: #8a73b4;
}
.prg2015_photo ul {
  border-radius: 0.5em;
  display: block;
  float: left;
  width: 218px;
  line-height: 1.4;
  position: relative;
  margin: 1.8em 0 1em;
  padding: 0.7em 0;
  font-size: $font_size_sm;
  @media only screen and (max-width: $sm) {
    margin: 1.8em auto;
    float: none;
  }
}
.prg2015_photo ul:first-child + ul,
.prg2015_photo ul:first-child + ul + ul + ul + ul,
.prg2015_photo ul:first-child + ul + ul + ul + ul + ul + ul + ul,
.prg2015_photo ul:first-child + ul + ul + ul + ul + ul + ul + ul + ul + ul + ul,
.prg2015_photo
  ul:first-child
  + ul
  + ul
  + ul
  + ul
  + ul
  + ul
  + ul
  + ul
  + ul
  + ul
  + ul
  + ul {
  margin-left: 14px;
  margin-right: 14px;
  @media only screen and (max-width: $sm) {
    margin: 0 auto;
  }
}
.prg2015_photo ul li {
  width: 90%;
  margin: 0 auto;
  display: block;
  color: #fff;
}
.prg2015_photo ul li:first-child {
  border-radius: 0.5em;
  position: absolute;
  top: -1.8em;
  right: 0;
  display: inline-block;
  width: auto;
  padding: 0.5em 0.7em 2em;
  z-index: -1;
  margin: 0;
  line-height: 1;
  font-weight: bold;
  font-size: $font_size_xs;
}
#ability + .prg2015_photo ul li:first-child.special,
#intelligence + .prg2015_photo ul li:first-child.special,
#expressive + .prg2015_photo ul li:first-child.special,
#sports + .prg2015_photo ul li:first-child.special,
#social + .prg2015_photo ul li:first-child.special,
#sentiment + .prg2015_photo ul li:first-child.special,
.prg2015_photo + .note span.special {
  background: #ceae87;
}
#ability + .prg2015_photo ul li:first-child.weekly,
#intelligence + .prg2015_photo ul li:first-child.weekly,
#expressive + .prg2015_photo ul li:first-child.weekly,
#sports + .prg2015_photo ul li:first-child.weekly,
#social + .prg2015_photo ul li:first-child.weekly,
#sentiment + .prg2015_photo ul li:first-child.weekly,
.prg2015_photo + .note span.weekly {
  background: #ec008c;
}
.prg2015_photo ul li:first-child + li {
  font-size: $font_size_lg;
  font-weight: bold;
  margin-bottom: 0.2em;
}
.prg2015_photo ul li:first-child + li + li + li {
  background: rgba(0, 0, 0, 0.34);
  border-radius: 0.5em;
  width: 100%;
  height: 140px;
  margin: 0.5em 0;
}
.prg2015_photo ul li:first-child + li + li + li img {
  border-radius: 0.5em;
  width: 218px;
  height: auto;
  display: block;
  overflow: hidden;
}
.prg2015_photo ul li:last-child {
  font-size: $font_size_xs;
}
.prg2015_photo ul li:last-child strong {
  display: block;
  font-size: $font_size_sm;
}
.prg2015_photo + .note {
  background: rgb(253, 239, 222);
  width: 92%;
  padding: 1.2em 4%;
  border-radius: 0.5em;
  margin-top: 3em;
}
.prg2015_photo + .note:before {
  content: "＜ ご注意 ＞";
  font-weight: bold;
  font-size: $font_size_sm;
  display: block;
  margin: -0.3em 0 0.3em;
}
.prg2015_photo + .note span {
  background: #999;
  border-radius: 0.5em;
  color: #fff;
  display: inline-block;
  font-size: $font_size_sm;
  font-weight: bold;
  line-height: 1;
  margin: 0 0.1em 0;
  padding: 0.3em 0.5em 0.2em;
  right: 0;
  width: auto;
}

.prg2015_photo {
  overflow: hidden;
  min-height: 500px;
  & + h3 {
    border-radius: 0.5em;
    position: relative;
    width: 627px;
    padding: 13px 20px 12px 35px;
    margin-bottom: 10px;
    margin-top: 30px;
    line-height: 1;
    color: #fff;
    @media only screen and (max-width: $sm) {
      width: auto;
    }
  }
}

/*--/program2015--*/

/*--communication_text--*/

.communication_text .box {
  background: url(img/line_green.gif) repeat-x bottom;
  margin-bottom: 20px;
  padding-bottom: 20px;
  overflow: hidden;
}
.communication_text .box img {
  float: left;
  margin: 0 18px 0 0;
}
.communication_text a.more_btn,
.communication_text a.more_btn:visited {
  background: url("img/more_btn.gif") no-repeat top;
  display: block;
  float: left;
  height: 41px;
  margin: 34px 0 0 10px;
  overflow: hidden;
  text-indent: -9999px;
  width: 122px;
}
.communication_text a.more_btn:hover {
  background-position: bottom;
}
.contents p + div.gallery {
  margin-top: 0;
}
/*--product--*/
.product {
  .product {
    width: 646px;
    margin: 0 auto;
  }
  .date,
  h3,
  .box {
    width: 646px;
    margin: 0 auto;
    @media only screen and (max-width: $sm) {
      width: auto;
    }
  }
  .date {
    font-size: $font_size_base;
    margin-bottom: 10px;
  }
  h3 {
    background: none;
    font-size: $font_size_xl;
    color: #000000;
    font-weight: bold;
    margin-bottom: 20px;
    padding: 0;
  }
  .box {
    background: url(img/line_green.gif) repeat-x bottom;
    margin-bottom: 20px;
    padding-bottom: 20px;
    overflow: hidden;
  }
  .box img {
    float: left;
    margin: 0 18px 0 0;
  }
  a.more_btn,
  a.more_btn:visited {
    background: url("img/more_btn.gif") no-repeat top;
    display: block;
    float: left;
    height: 41px;
    margin: 34px 0 0 10px;
    overflow: hidden;
    text-indent: -9999px;
    width: 122px;
  }
  a.more_btn:hover {
    background-position: bottom;
  }
}

.contents p + div.gallery {
  margin-top: 0;
}
/*--voice--*/

/*--news,blog--*/
.news .contents,
.blog .contents {
  width: 646px;
  margin: 0 auto;
  @media only screen and (max-width: $sm) {
    padding: 0 $gutter-width;
  }
}

.news .date,
.blog .date,
.information .date {
  font-size: $font_size_sm;
  width: 646px;
  margin: 0 auto 25px;
  @media only screen and (max-width: $sm) {
    width: auto;
  }
}

.blog .contents_wrap img {
  max-width: 646px;
  margin: 10px;
  @media only screen and (max-width: $sm) {
    max-width: 100%;
  }
  &.alignleft {
    margin: 10px 10px 10px 0;
  }
  &.alignright {
    margin: 10px 0 10px 10px;
  }
  &.aligncenter {
    margin: 10px center;
  }
}

.news .list,
.blog .list {
  dl {
    overflow: hidden;
    margin: 0 auto 20px;
    width: 646px;
    line-height: 1.4;
    @media only screen and (max-width: $sm) {
      width: auto;
      margin: 0;
    }
  }
  dt {
    float: left;
    clear: both;
    margin: 0 10px 0 0;
    // width: 130px;
    line-height: 1.2;
    @media only screen and (max-width: $sm) {
      float: none;
      margin: 0 0 0.5em 0;
    }
  }
  dd {
    float: left;
    width: 506px;
    @media only screen and (max-width: $sm) {
      float: none;
      width: auto;
    }
  }
}

.news h3,
.blog h3,
.information h3 {
  background: none;
  font-size: $font_size_xl;
  color: #000000;
  font-weight: bold;
  width: 646px;
  margin: 0 auto 30px;
  padding: 0;
  @media only screen and (max-width: $sm) {
    width: auto;
  }
}

.information .list dl {
  line-height: 1.4;
  margin: 0 auto;
  overflow: hidden;
  width: 646px;
}
.information .list dt {
  clear: both;
  float: left;
  line-height: 1.2;
  width: 130px;
  margin: 0 10px 15px 0;
}
.information .list dd {
  float: left;
  width: 506px;
  margin-bottom: 15px;
}
.information h4 {
  font-size: $font_size_xl;
  width: 646px;
  margin: 0 auto 20px;
}

.comments-area {
  clear: both;
  padding: 3em 0 0;
  .comments-title {
    border: none;
    margin: 0 0 1em;
    padding: 1em;
    width: auto;
    height: auto;
    border-radius: $border_radius;
    background: $yellow_green_right_1;
    font-size: $font_size_lg;
  }
}

.comment-list {
  margin: 0 0 50px;
  padding: 0 $gutter-width;
  ol {
    list-style: none;
  }
  .comment-body {
    margin-bottom: 10px !important;
    border-bottom: dashed 1px $yellow_green_brilliant_3;
  }
  .communication_text {
    margin-left: 50px;
  }
  .reply {
    margin: -1em 0 1em !important;
  }
  .children {
    margin: 0 0 1em 1em;
  }
  .comment-author,
  .comment-meta {
    margin-bottom: 1em !important;
  }
  .comment-meta + p {
    padding: 0;
    margin-bottom: 1em !important;
  }
}

.blog {
  img.avatar {
    margin: 0 5px 0 0 !important;
    vertical-align: middle;
    display: inline-block;
  }

  #commentform {
    margin-left: 16px;
    @media only screen and (max-width: $sm) {
      margin: 0;
    }
    input#author,
    input#email,
    input#url,
    textarea {
      border: 1px solid #93c641;
      background: #f9fff2;
    }
    .logged-in-as {
      margin-bottom: 0.5em;
    }
    label {
      display: block;
    }
    textarea {
      width: 600px;
      @media only screen and (max-width: $sm) {
        width: 100%;
      }
    }
    p {
      margin-bottom: 20px;
    }
  }

  .nocomments {
    margin-top: 50px;
    padding: 6px 15px;
    width: 616px;
    border: solid 1px #cccccc;
    border-radius: 8px;
    color: #999999;
    clear: both;
  }
}
.blog #respond h3 {
  background: none;
  border: solid 1px #93c641;
  border-radius: 8px;
  font-size: $font_size_xl;
  font-weight: normal;
  color: $gray_dark_1;
  width: 614px;
  padding: 6px 15px;
  margin-bottom: 20px;
  clear: both;
  @media only screen and (max-width: $sm) {
    width: auto;
  }
}

/*--information--*/
.information .contents {
  width: 646px;
  margin: 0 auto;
}

/*--recruit--*/

/*--sitemap--*/

/*--privacy--*/

/*--not found--*/
.notfound {
  text-align: center;
  height: 200px;
  padding-top: 100px;
}
.notfound p {
  margin-bottom: 20px;
}
/*--wp gallery--*/
/*--facilities--*/
.facilities p img {
  margin: 0 auto;
  display: block;
  @media only screen and (max-width: $sm) {
    width: 100%;
    height: auto;
  }
}
.contents_wrap .facilities p {
  margin-bottom: 10px;
  @media only screen and (max-width: $sm) {
    margin-bottom: 10px !important;
  }
}
.facilities p.smalll,
.facilities p.smallr,
.facilities p.smalll img,
.facilities p.smallr img {
  width: 312px;
}
.facilities p.smalll,
.facilities p.smallr {
  @media only screen and (max-width: $sm) {
    width: 100%;
    height: auto;
    padding: 0 $gutter-width;
    max-width: $contents_wrap_img_max_width;
  }
}

.facilities p.smalll img,
.facilities p.smallr img {
  @media only screen and (max-width: $sm) {
    width: 100%;
    height: auto;
  }
}

.facilities p.smalll {
  float: left;
  margin: 0 0 10px 18px;
}
.facilities p.smallr {
  float: right;
  margin: 0 18px 10px 0;
}
/*--informationlist--*/
.other .home_informationlist h4 {
  font-size: $font_size_xl;
  font-weight: bold;
  width: 646px;
  margin: 0 auto 10px;
  @media only screen and (max-width: $sm) {
    width: 100%;
  }
}
.other .home_informationlist p {
  background: url("img/line_green.gif") repeat-x bottom;
  padding-bottom: 20px;
  margin-bottom: 30px;
}

/*--------------------
home contents
--------------------*/
@import "_home";

/*--------------------
side
--------------------*/
@import "_side";

/*--------------------
footer
--------------------*/
@import "_footer";

/*--------------------
type
--------------------*/
@import "_type";

@import "_mycalender";

/*--RSS--*/
.rss {
  width: 646px;
  margin: 0 auto 20px;
  overflow: hidden;
}
.rss a,
.rss a:visited {
  background: #ea5504;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  width: 26px;
  float: right;
  padding: 4px 8px 2px 6px;
  @media only screen and (max-width: $sm) {
    font-size: 14px;
  }
}
.rss a:hover {
  color: #fff;
  text-decoration: none;
}

.mycalendar {
  @media only screen and (max-width: $sm) {
    h2.heading {
      border-radius: 0;
    }
    .rss {
      width: auto !important;
    }
    .mc-export li {
      margin-bottom: 1em;
    }
    .mc-main {
      div,
      p {
        margin-bottom: 0.5em !important;
      }
      .category-icon {
        display: inline-block !important;
      }
      div.mc_edit_links,
      div.list-event,
      div.details,
      div.mc_edit_links p {
        margin-bottom: 0 !important;
      }
      .mc-list li {
        padding: 8px 5px;
      }
      .mc-print {
        display: none;
      }
      .mc-time a,
      .mc-time .mc-active {
        margin-right: 1em;
      }
      .list-event .event-time,
      .list-event .end-time {
        width: auto !important;
        font-size: 14px;
        font-weight: bold;
        margin: 0 !important;
        display: inline-block;
        float: none;
      }
      .event-title {
        font-size: 16px !important;
      }
    }
  }
}

/*--
.mc-main .my-calendar-date-switcher, .mc-main .calendar-event .details, .mc-main .calendar-events {
    background:#e6f0f9 !important;
    border:solid 1px #9ecef7;
}
.mc-main .my-calendar-prev a,.mc-main .my-calendar-next a {
    border-radius:0 !important;
}
--*/

/* google map */
.googlemap {
  @media only screen and (max-width: $sm) {
    width: 100% !important;
  }
  iframe {
    @media only screen and (max-width: $sm) {
      width: 100% !important;
    }
  }
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.clearfix {
  display: inline-block;
}

/* Hides from IE-mac \*/
* html .clearfix {
  height: 1%;
}
.clearfix {
  display: block;
}
/* End hide from IE-mac */
