.home .side {
  margin: 12px 20px 0 0;
  @media only screen and (max-width: $sm) {
    margin: 0;
  }
}
.side {
  width: 238px;
  float: right;
  @media only screen and (max-width: $sm) {
    float: none;
    width: auto;
  }
}
.submenu {
  background: url(img/line_blue.gif) repeat-x bottom;
  width: 100%;
  margin-bottom: 40px;
  @media only screen and (max-width: $sm) {
    display: none;
  }
}
.submenu li {
  background: url(img/line_blue.gif) repeat-x top;
  line-height: 1.4;
}
.submenu li:first-child {
  background: none;
}
.submenu li a,
.submenu li a:visited {
  background: url(img/submenu_bullet.gif) no-repeat 2px 16px;
  width: 206px;
  min-height: 26px;
  padding: 16px 0 6px 28px;
  display: block;
  overflow: hidden;
}
.submenu li a:hover,
.submenu li a.select {
  background-position: -236px 16px;
  color: $red_dark_1;
  text-decoration: none;
}
.submenu li li,
.submenu li.sub,
.submenu li.navlist_sub {
  background: none;
  margin: -4px 0 6px;
}
.submenu li li a,
.submenu li li a:visited,
.submenu li.sub a,
.submenu li.sub a:visited,
.submenu li.navlist_sub a,
.submenu li.navlist_sub a:visited {
  background: url(img/submenu_bullet_arrow.gif) no-repeat 2px 10px;
  width: 80px;
  min-height: 26px;
  margin-left: 20px;
  padding: 6px 0 0 15px;
  display: block;
  overflow: hidden;
}
.submenu li.sub a,
.submenu li.sub a:visited,
.submenu li.navlist_sub a,
.submenu li.navlist_sub a:visited {
  width: auto;
}
.submenu li li a:hover,
.submenu li li a.select,
.submenu li.sub a:hover,
.submenu li.sub a.select,
.submenu li.navlist_sub a:hover,
.submenu li.navlist_sub a.select,
.submenu li.current a {
  background-position: -249px 10px;
  color: $red_dark_1;
  text-decoration: none;
}
.submenu a + ul {
  margin-top: -10px;
  padding-bottom: 2px;
}
.side .facebook {
  margin: 0 0 13px;
  @media only screen and (max-width: $sm) {
    text-align: center;
    margin-bottom: $spacer_2;
  }
}
.side .facebook a.h,
.side .news a.h,
.side .blog a.h {
  text-indent: -9999px;
  width: 238px;
  height: 36px;
  display: block;
  overflow: hidden;
}
.side .facebook a.h,
.side .facebook a.h:visited {
  background: url(img/side_h.gif) no-repeat 0 top;
  @media only screen and (max-width: $sm) {
    margin: 0 auto;
  }
}
.side .facebook a.h:hover {
  background-position: left bottom;
}

.side .news,
.side .blog {
  margin-bottom: 20px;
  @media only screen and (max-width: $sm) {
    width: 238px;
    margin: 0 auto $spacer_2;
  }
}
.side .news a.h,
.side .news a.h:visited {
  background: url(img/side_h.gif) no-repeat -476px top;
}
.side .news a.h:hover {
  background-position: -476px bottom;
}
.side .blog a.h,
.side .blog a.h:visited {
  background: url(img/side_h.gif) no-repeat -238px top;
  @media only screen and (max-width: $sm) {
    margin: 0 auto;
  }
}
.side .blog a.h:hover {
  background-position: -238px bottom;
}
.side .facebook .txt {
  width: 236px;
  margin: 0 auto;
}
.side .news .txt,
.side .blog .txt {
  border-bottom: #cccccc solid 1px;
  line-height: 1.4;
  padding: 4px 6px 8px;
}
.side .news .date,
.side .blog .date {
  font-size: $font_size_sm;
  line-height: 2;
}
a.banner_disaster_prevention,
a.banner_channel {
  text-indent: -9999px;
  width: 238px;
  height: 50px;
  display: block;
  overflow: hidden;
  margin-bottom: 20px;
  @media only screen and (max-width: $sm) {
    margin: 0 auto $spacer_2;
  }
}
a.banner_disaster_prevention,
a.banner_disaster_prevention:visited {
  background: url(img/banner.gif) no-repeat 0 top;
}
a.banner_channel,
a.banner_channel:visited {
  background: url(img/banner.gif) no-repeat -238px top;
}
a.banner_disaster_prevention:hover {
  background-position: 0 bottom;
}
a.banner_channel:hover {
  background-position: -238px bottom;
}

a.banner_thinkthink {
  background: url(img/tk_logo.png) no-repeat;
  background-size: 100%;
  text-indent: -9999px;
  width: 238px;
  height: 50px;
  display: block;
  overflow: hidden;
  margin: 0 auto 30px;
}
