

/*--MyCalendar--*/
.mycalendar {
    width:646px;
    margin:0 auto;
}
.contents .my-calendar-table p {
    width:auto;
}

.contents_wrap .my-calendar-header {
    overflow: hidden;
    @media only screen and (max-width: $sm) {
        margin: 0 !important;
    }
    .my-calendar-nav {
        @media only screen and (max-width: $sm) {
            margin: 0 !important;
        }
    }
}

.contents_wrap .mc-main h3 {
    background:none;
    color:inherit;
    font-size: $font_size_sm !important;
    line-height:1.2;
}
.mc-main .calendar-event .details, .mc-main .calendar-events {
    width:60% !important;
    left:15% !important;
}
.mc-main img {
    margin:0;
}
.mc-main .calendar-event .event-time, .mc-main .list-event .event-time {
    width:352px !important;
    margin-top:5px;
    margin-bottom:5px !important;
    margin-right:0 !important;
}
.mc_edit_links ul {
    margin:0 10px !important;
}
.mc_edit_links    li {
    width:10%;
    float:left;
}
