.totop {
    width:960px;
    margin:0 auto;
    overflow:hidden;
    @media only screen and (max-width: $sm) {
        width: auto;
    }
    a,
    a:visited {
        background:url(img/totop.gif) no-repeat top;
        text-indent:-9999px;
        width:141px;
        height:35px;
        display:block;
        overflow:hidden;
        float:right;
        @media only screen and (max-width: $sm) {
            float: none;
            margin: 0 auto;
        }
    }
    a:hover {
        background-position:bottom;
    }
}

.program_description .totop {
    width:646px;
    margin-bottom:50px;
    a,
    a:visited {
        background:url(img/totop2.gif) no-repeat top;
        width:130px;
        height:25px;
    }
}

footer {
    background:url(img/footer_bg_bottom.gif) repeat-x bottom;
    font-size: $font_size_sm;
    line-height:1.8;
    padding-bottom:15px;
    clear:both;
    display:block;
    width:100%;
    overflow:hidden;
    .inner {
        background:#e6f0f9 url(img/footer_bg_top.gif) repeat-x top;
        overflow:hidden;
        margin-top:-3px;
        padding-top:15px;
        clear:right;
    }
    .footer_wrap {
        width:960px;
        margin:0 auto;
        padding:0 16px;
        overflow:hidden;
        @media only screen and (max-width: $sm) {
            width: auto;
        }
    }
    dl {
        float:left;
        margin-right:20px;
        max-width:150px;
        @media only screen and (max-width: $sm) {
            display: none;
            max-width: none;
            text-align: center;
            margin: 0 0 2em;
            &.base_info {
                display: block;
                float: none;
                font-size: 16px;
                dd {
                    display: inline-block;
                }
            }
        }
    }
    dt {
        margin-bottom:6px;
    }
    dd {
        background:url(img/footer_bullet.gif) no-repeat 2px 5px;
        line-height:1.2;
        padding: 0 0 8px 12px;
        margin: 0;
        @media only screen and (max-width: $sm) {
            background: none;
            padding: 0 0 1em;
            margin: 0 .8em;
        }
    }
}

footer .footer_info {
    text-align:center;
    width:200px;
    float:right;
    margin-top:3px;
    @media only screen and (max-width: $sm) {
        display: block;
        float: none;
        margin: 0 auto;
    }
    .txt {
        background:url(img/footer_info.gif) no-repeat;
        text-indent:-9999px;
        height:58px;
        overflow:hidden;
    }
    a {
        font-size: $font_size_sm;
        color:#3F1F00;
        font-weight: bold;
    }
}


.copy {
    margin:10px auto 20px;
    width:960px;
    text-align: center;
    font-size: $font_size_sm;
    line-height: 1.6;
    @media only screen and (max-width: $sm) {
        width: auto;
    }
}
