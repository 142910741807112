.home nav {
    margin: -30px 0 0;
    @media only screen and (max-width: $sm) {
        margin: 0;
    }
}

.home .wrap {
    margin-bottom:0;
    padding:0;
    width:1000px;
    background:url(img/home_news_bgbg.jpg) no-repeat center top;
    @media only screen and (max-width: $sm) {
        width: auto;
        background: none;
    }
}

.home .home_contents_wrap_bg {
    width:1000px;
    margin:0 auto;
    overflow:hidden;
    @media only screen and (max-width: $sm) {
        width: auto;
    }
}
.home .home_contents_wrap {
    float:left;
    width:728px;
    @media only screen and (max-width: $sm) {
        float: none;
        width: auto;
    }
}
.home h3 {
    background:none;
}
.home .news {
    float:left;
    overflow:hidden;
    margin:9px 8px 20px 18px;
    padding:23px 0 10px 0;
    width:350px;
    background:url(img/news_bg_bottom.jpg) no-repeat bottom;
    @media only screen and (max-width: $sm) {
        float: none;
        margin: 0;
        padding: 0;
        width: auto;
        background: none;
    }
}

.home .news h3 {
    width:314px;
    margin:0;
    @media only screen and (max-width: $sm) {
        border-bottom: 5px solid $orange_brilliant_2;
        margin: 0 0 .5em;
        width: auto;
        padding: 0 6px;
        img, map, area {
            display: none;
        }
    }
    .heading-txt {
        display: none;
        overflow: hidden;
        padding: 0 0 .3em;
        @media only screen and (max-width: $sm) {
            display: block;
        }
        .head {
            float: left;
        }
        .ctrl {
            float: right;
        }
        .rss, .to-list {
            position: relative;
            margin-left: .5em;
            padding-right: 1.5em;
            color: $gray_dark_1;
            font-weight: normal;
            font-size: $font_size_sm;
            &:after {
                position: absolute;
                top: .25em;
                right: -.5em;
                display: block;
                width: 0;
                height: 0;
                border-width: .4em .7em;
                border-style: solid;
                border-color: transparent transparent transparent $gray_dark_1;
                content: "";
            }
        }
    }
}

.home .news .inner {
    padding:0 20px 10px 16px;
    width:314px;
    background:url(img/home_news_bg.jpg) no-repeat top;
    @media only screen and (max-width: $sm) {
        overflow: auto;
        margin: .5em auto $spacer_2;
        padding: 0;
        max-width: 346px;
        width: auto;
        background: none;
    }
}
.home .news_txt .txt {
    background:url(img/home_news_line.gif) repeat-x bottom;
    line-height:1.6;
    margin-bottom:10px;
    padding:0 6px 14px;
    clear:both;
    overflow:hidden;
}
.home .news_txt .date {
    font-size: $font_size_sm;
    line-height:2;
}
.home .news_txt img {
    width:60px;
    float:right;
    margin:2px 0 0 10px;
}
.home .info {
    float:left;
    margin:9px 0 20px;
    padding-bottom:10px;
    background:url(img/info_bg_bottom.jpg) no-repeat bottom;
    @media only screen and (max-width: $sm) {
        float: none;
        margin: 0;
        padding: 0 .5em;
        width: auto;
        background: none;
    }
}
.home .info .inner {
    background:url(img/home_info_bg.jpg) no-repeat top;
    width:346px;
    padding-bottom:10px;
    overflow:hidden;
    @media only screen and (max-width: $sm) {
        margin: 0 auto $spacer_2;
        overflow: auto;
        background: #145324;
        border: 5px solid #9c7b52;
        max-width: 346px;
        width: auto;

    }
}
.home .info h3 {
    margin-bottom:12px;
    padding:10px 10px 0;
    width:306px;
    height:49px;
    @media only screen and (max-width: $sm) {
        margin: 0;
        padding: .5em 1em;
        width: auto;
        height: auto;
        border-bottom: 2px solid #b2db1e;
        color: #fff;
        img, map, area {
            display: none;
        }

    }
    .heading-txt {
        display: none;
        @media only screen and (max-width: $sm) {
            display: block;
        }
        .rss, .to-list {
            font-weight: normal;
            font-size: $font_size_sm;
        }
    }
}
.home .info h4,.home .info p {
    margin:0 auto;
    width:286px;
    color:#fff;
    @media only screen and (max-width: $sm) {
        margin: 0 1em 1em;
        width: auto;
        padding: 0;
    }
}
.home .info h4 {
    font-size: $font_size_lg;
    font-weight:bold;
    line-height:1.4;
    margin:13px auto 4px;
    @media only screen and (max-width: $sm) {
        margin: 1em 1em .5em;
        font-weight: normal;
    }
}
.home .info p {
    background:url(img/home_info_line.gif) repeat-x bottom;
    line-height:1.6;
    padding-bottom:13px;
}
.home .info a img {
    display:block;
    margin:5px 0 4px;
    line-height:1;
}
.home .info a,.home .info a:visited {
    color:#b2db1e;
}
.home .info a:hover {
    text-decoration:underline;
    color:#b2db1e;
}
.home_direct {
    clear:both;
    width:728px;
    height:272px;
    background:url(img/home_direct.jpg) no-repeat;
    @media only screen and (max-width: $sm) {
        margin: 0 auto;
        padding: 0 .5em;
        max-width: 360px;
        width: auto;
        height: auto;
        background: none;
    }
    a {
        display:block;
        float:left;
        overflow:hidden;
        width:310px;
        height:36px;
        text-indent:-9999px;
        @media only screen and (max-width: $sm) {
            position: relative;
            float: none;
            padding: .5em 1em .4em;
            width: auto;
            height: auto;
            border-radius: .5em;
            color: #fff;
            text-align: center;
            text-indent: inherit;
            &:after {
                position: absolute;
                top: .8em;
                right: 0em;
                display: block;
                width: 0;
                height: 0;
                border-width: .5em .6em;
                border-style: solid;
                border-color: transparent transparent transparent #fff;
                content: "";
            }
        }
    }
    a.btn01,
    a.btn01:visited {
        margin:224px 48px 0 34px;
        background:url(img/home_direct_btn01.gif) no-repeat top;
        @media only screen and (max-width: $sm) {
            margin: 0 0 $spacer_2;
            background: $blue_mid_1;
            color: #fff;
            font-weight: bold;
        }
    }
    a.btn02,
    a.btn02:visited {
        margin:224px 0 0 0;
        background:url(img/home_direct_btn02.gif) no-repeat top;
        @media only screen and (max-width: $sm) {
            margin: 0 0 $spacer_2;
            background: $blue_mid_1;
            color: #fff;
            font-weight: bold;
        }
    }
    a.btn01:hover,
    a.btn02:hover {
        background-position:bottom;
        @media only screen and (max-width: $sm) {
            text-decoration: none;
            opacity: .7;
        }
    }
    .home_direct_sm1,
    .home_direct_sm2 {
        display: none;
        @media only screen and (max-width: $sm) {
            display: block;
        }
        img {
            display: block;
            box-sizing: border-box;
            margin: 0 auto;
            width: 100%;
            border: 4px solid #fff;
            box-shadow: 0em 0.2em 1em 0em rgba(0, 0, 0, .4);
        }
    }
}



/*--------------------
mainvisual
--------------------*/
.mainvisual {
    margin-bottom:3px;
    height:298px;
    background:#ffffff url(img/mainvisual_bg.gif) no-repeat center top;
    @media only screen and (max-width: $sm) {
        padding-top: 7px;
        height: auto;
        background:url(img/mainvisual_top_line.gif) repeat-x center top -1px;
    }
}
.mainvisual_bottom_line {
    padding-bottom:8px;
    height:290px;
    background:url(img/mainvisual_bottom_line.gif) repeat-x bottom;
    @media only screen and (max-width: $sm) {
        height: auto;
        padding-bottom: 8px;
    }
}

.mainvisual {

    .imgslider {
        background: #ffffff url(img/mainvisual_bg.gif) no-repeat center top;
        width: 1000px;
        height: 290px;
        margin: 0 auto;
        z-index: 0;
        position: relative;
        .img1, .img2, .img3 {
            position: absolute;
            top: 0;
            left: 0;
        }
        .img2, .img3 {
            display: none;
        }

        img.mainvisual-pc {
            display: block;
        }
        img.mainvisual-sm {
            display: none;
        }

        @media only screen and (max-width: $sm) {
            background: none;
            width: 100%;
            height: auto;
            img.mainvisual-pc {
                display: none;
            }
            img.mainvisual-sm {
                display: block;
                width: 100%;
                height: auto;
            }
        }
    }
    .img-sm-bg {
        display: none;
        @media only screen and (max-width: $sm) {
            display: block;
        }
        img {
            width: 100%;
            display: block;
        }
    }
}
