h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.4;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
p {
  margin-bottom: 25px;
}
ul,
ul li,
dl,
dt,
dd {
  list-style: none;
  margin: 0;
  padding: 0;
}
ol {
  list-style-position: inside;
  margin: 0 0 25px 3px;
  padding: 0;
}
ol li {
  margin: 0;
}
table {
  border-collapse: collapse;
  margin: 0;
}

a,
a:visited {
  color: $blue_light_1;
  text-decoration: none;
}
a:hover {
  color: $red_dark_1;
  text-decoration: underline;
}
strong {
  font-weight: bold;
}

h2 {
  background: url(img/h2_bg.gif) no-repeat;
  font-size: $font_size_xxl;
  font-weight: normal;
  width: 529px;
  height: 76px;
  margin-bottom: 20px;
  padding: 40px 120px 0 45px;
  overflow: hidden;
  @media only screen and (max-width: $sm) {
    width: auto;
    background: none;
    border: 6px solid $nav_border_color_1;
    border-radius: $border_radius_h2;
    padding: 12px 15px 11px;
    height: auto;
    line-height: 1.4;
  }
}
.about h2 {
  background-position: 0 0;
}
.join h2 {
  background-position: 0 -116px;
}
.program h2 {
  background-position: 0 -232px;
}
.communication_text h2 {
  background-position: 0 -348px;
}
.news h2 {
  background-position: 0 -464px;
}
.information h2,
.recruit h2,
.sitemap h2,
.privacy h2 {
  background-position: 0 -580px;
}
.blog h2 {
  background-position: 0 -696px;
}

.contents_wrap {
  div,
  p {
    @media only screen and (max-width: $sm) {
      margin: 0 auto 2em !important;
      float: none !important;
      width: auto !important;
    }
  }
  p {
    @media only screen and (max-width: $sm) {
      padding: 0 $gutter-width;
      clear: both;
    }
  }
}

.contents_wrap div.h3 {
  /* h3 background by jquery */
  background: url(img/h3_bg.gif) no-repeat 0 bottom;
  margin-bottom: 20px;
  padding-bottom: 10px;
  clear: both;
  @media only screen and (max-width: $sm) {
    background: $yellow_green_brilliant_1;
    padding: 10px 12px 8px;
    border-radius: $border_radius_h3;
    margin-bottom: 20px !important;
  }
  h3 {
    /* h3 background by jquery */
    background: url(img/h3_bg.gif) no-repeat 0 top;
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    padding: 12px 12px 0;
    clear: both;
    @media only screen and (max-width: $sm) {
      background: none;
      width: auto;
      padding: 0;
    }
  }
}

.contents h4.year {
  font-size: $font_size_xl;
  width: 646px;
  margin: 0 auto 20px;
}

.promise5 {
  div.h3 {
    /* h3 background by jquery */
    background-position: -682px bottom;
    @media only screen and (max-width: $sm) {
      background: $red_brilliant_1;
    }
    h3 {
      /* h3 background by jquery */
      background-position: -682px top;
    }
  }
  h4 {
    background: url(img/primies5_bullet.gif) no-repeat;
    font-size: $font_size_xl;
    font-weight: bold;
    color: $red_brilliant_1;
    width: 566px;
    height: 38px;
    margin: 0 auto;
    padding: 14px 0 0 70px;
    overflow: hidden;
    line-height: 1.2;
    @media only screen and (max-width: $sm) {
      position: relative;
      padding: 0.25em 0 0.5em 2em;
      width: auto;
      height: auto;
      background: none;
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        padding-top: 0.1em;
        width: 1.5em;
        height: 1.4em;
        border-radius: 1em;
        background: $red_brilliant_1;
        color: #fff;
        content: "1";
        line-height: 1;

        align-items: center;
        justify-content: center;
      }
    }
    &.no2 {
      background-position: 0 -52px;
      @media only screen and (max-width: $sm) {
        &:before {
          content: "2";
        }
      }
    }
    &.no3 {
      background-position: 0 -104px;
      @media only screen and (max-width: $sm) {
        &:before {
          content: "3";
        }
      }
    }
    &.no4 {
      background-position: 0 -156px;
      @media only screen and (max-width: $sm) {
        &:before {
          content: "4";
        }
      }
    }
    &.no5 {
      background-position: 0 -208px;
      @media only screen and (max-width: $sm) {
        &:before {
          content: "5";
        }
      }
    }
  }

  p {
    background: url(img/line_red.gif) repeat-x bottom;
    line-height: 1.4;
    width: 570px;
    margin: -5px auto 8px;
    padding: 0 0 20px 76px;
    @media only screen and (max-width: $sm) {
      width: auto;
      padding: 0 $gutter-width 20px;
      margin-bottom: $gutter-width !important;
    }
  }
}

.company h4,
.access h4 {
  font-size: $font_size_xl;
  width: 646px;
  margin: 0 auto 20px;
  @media only screen and (max-width: $sm) {
    width: auto;
  }
}
.company table {
  margin: 0 0 30px 18px;
}
.company table td,
.access table td {
  vertical-align: top;
  padding: 0 20px 10px 0;
}

.contents_wrap .lineup .lineupcourse {
  div.h3 {
    /* h3 background by jquery */
    margin-bottom: 20px;
    padding-bottom: 0;
    background: none;
    @media only screen and (max-width: $sm) {
      width: auto;
      background: $skyblue_brilliant_1;
      border-radius: $border_radius_h3;
      padding: 0.4em 0.5em 0.7em;
      img {
        display: none;
      }
    }
    h3 {
      /* h3 background by jquery */
      margin-bottom: 0;
      padding: 0;
      width: 682px;
      height: 44px;
      background: none; /* for off javascript */
      color: #000;
      font-size: 0;
      @media only screen and (max-width: $sm) {
        width: auto;
        &:after {
          content: "みんなの学校では、3つのコースをご用意しています";
          font-size: $font_size_xl;
          color: #fff;
        }
      }
    }
  }
}

.lineupcourse .summary {
  div h4 {
    height: 48px;
    border-radius: 10px 10px 0 0;
  }
  .regular h4 {
    background: #8ec741;
  }
  .program h4 {
    background: #f39221;
  }
  .spot h4 {
    background: #9fa19f;
  }
  div h4 img {
    margin: 0 auto;
    padding-top: 15px;
    display: block;
  }
  div p {
    width: auto;
    margin: 0 !important;
    padding: 10px 10px 8px;
    @media only screen and (max-width: $sm) {
      padding-bottom: 0;
    }
  }
}

.regularcourse .summary .txt {
  line-height: 1.6;
  width: 312px;
  padding: 20px 0 18px 20px;
  @media only screen and (max-width: $sm) {
    width: auto;
    padding: $gutter-width !important;
    margin-bottom: 0 !important;
  }
}

.join table.price {
  width: 300px;
  float: left;
  margin: 0 0 40px 18px;
  @media only screen and (max-width: $sm) {
    margin: 0 0 2em;
    & + table.price {
      margin-top: -2em;
    }
  }
  th {
    background: #e3f1d1;
    border-bottom: solid 3px #fff;
    font-weight: normal;
    text-align: left;
    vertical-align: top;
    min-width: 80px;
    padding: 5px 10px;
    @media only screen and (max-width: $sm) {
      width: 20%;
    }
  }
  td {
    vertical-align: top;
    border-bottom: solid 3px #fff;
    padding: 5px 10px;
  }
}

.programcourse .summary .txt {
  line-height: 1.6;
  width: 312px;
  padding: 20px 0 18px 20px;
  float: left;
}
.contents_wrap .programcourse {
  h3 {
    background: #f4921e; /* for off javascript */
    font-size: $font_size_xl;
    font-weight: bold;
    color: #ffffff;
    width: 642px;
    padding: 12px 20px;
    margin-bottom: 20px;
  }
  div.h3 {
    /* h3 background by jquery */
    background: url(img/h3_bg.gif) no-repeat -2046px bottom;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }
  div.h3 h3 {
    /* h3 background by jquery */
    background: url(img/h3_bg.gif) no-repeat -2046px top;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.join .programcourse {
  table.price {
    width: 300px;
    float: left;
    margin: 0 0 40px 18px;
    th {
      background: #ffefdf;
      border-bottom: solid 3px #fff;
      font-weight: normal;
      text-align: left;
      vertical-align: top;
      min-width: 80px;
      padding: 5px 10px;
    }
    td {
      vertical-align: top;
      border-bottom: solid 3px #fff;
      padding: 5px 10px;
    }
  }
}
/*--spotcourse--*/
.spotcourse .summary {
  background: url(img/cours_bg_snap_bottom.jpg) no-repeat -1292px bottom;
  width: 646px;
  margin: 0 auto 30px;
  padding-bottom: 13px;
  overflow: hidden;
  @media only screen and (max-width: $sm) {
    background: none;
    background-color: $gray_pale_1;
    border-radius: $border_radius;
    width: auto;
    padding: $gutter-width;
  }
  .inner {
    background: url(img/cours_bg_snap.jpg) no-repeat -1292px 0;
    width: 646px;
    height: 150px;
    margin: 0 !important;
    padding: 0;
    @media only screen and (max-width: $sm) {
      background: none;
      width: auto;
      height: auto;
    }
  }
  .txt {
    line-height: 1.6;
    width: 312px;
    padding: 20px 0 18px 20px;
    float: left;
    @media only screen and (max-width: $sm) {
      width: auto;
      margin: 0 !important;
      padding: 0;
    }
  }
}
.contents_wrap .spotcourse {
  div.h3 {
    margin-bottom: 20px;
    padding-bottom: 10px;
    /* h3 background by jquery */
    background: url(img/h3_bg.gif) no-repeat -2728px bottom;
    @media only screen and (max-width: $sm) {
      background: none;
      background-color: $gray_mid_1;
    }
  }
  div.h3 h3 {
    margin-bottom: 0;
    padding: 12px 20px 0;
    width: 642px;
    background: $gray_mid_1 url(img/h3_bg.gif) no-repeat -2728px top;
    color: #ffffff;
    font-weight: bold;
    /* h3 background by jquery */
    font-size: $font_size_xl;
    @media only screen and (max-width: $sm) {
      background: none;
      width: auto;
      padding: 0;
      margin: 0;
    }
  }
}

.spotcourse .join table.price {
  width: 300px;
  float: left;
  margin: 0 0 40px 18px;
}

.join .spotcourse {
  table.price {
    th {
      background: $gray_pale_1;
      border-bottom: solid 3px #fff;
      font-weight: normal;
      text-align: left;
      vertical-align: top;
      padding: 5px 10px;
    }
    td {
      vertical-align: top;
      border-bottom: solid 3px #fff;
      padding: 5px 10px;
    }
  }
  .option {
    h4 {
      /* for off javascript */
      background: $yellow_green_brilliant_2;
      font-size: $font_size_xl;
      font-weight: bold;
      line-height: 1.4;
      color: #ffffff;
      width: 682px;
      float: left;
      margin: 0;
      padding: 10px 18px;
    }
    div.h4 {
      /* h4 background by jquery */
      background: url(img/option_timeline_h4_bg.gif) no-repeat -668px bottom;
      margin-bottom: 20px;
      padding-bottom: 8px;
      overflow: hidden;
    }
    div.h4 h4 {
      /* h4 background by jquery */
      background: url(img/option_timeline_h4_bg.gif) no-repeat -668px top;
      padding-bottom: 0;
    }
  }

  table.price,
  .price_supplement {
    width: 646px;
    float: none;
    margin: 0 auto 20px;
    @media only screen and (max-width: $sm) {
      width: auto;
    }
  }
  .price_supplement {
    dl {
      margin-bottom: 10px;
    }
    dd {
      background: url("img/bullet_gray01.gif") no-repeat 3px 8px;
      padding-left: 23px;
    }
  }
}

.contents_wrap .specialcourse h3 {
  background: #ac83d6; /* for off javascript */
  font-size: $font_size_xl;
  font-weight: bold;
  color: #ffffff;
  width: 642px;
  padding: 12px 20px;
  margin-bottom: 20px;
}
.contents_wrap .specialcourse div.h3 {
  /* h3 background by jquery */
  background: url(img/h3_bg.gif) no-repeat -3410px bottom;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.contents_wrap .specialcourse div.h3 h3 {
  /* h3 background by jquery */
  background: url(img/h3_bg.gif) no-repeat -3410px top;
  margin-bottom: 0;
  padding-bottom: 0;
}
.experience .summary .txt {
  line-height: 1.6;
  width: 606;
  padding: 20px 20px 18px;
  float: left;
}

.contents_wrap .experience {
  div.h3 {
    margin-bottom: 20px;
    padding-bottom: 10px;
    /* h3 background by jquery */
    background: url(img/h3_bg.gif) no-repeat -1364px bottom;
    @media only screen and (max-width: $sm) {
      background: none;
      background-color: $skyblue_brilliant_2;
    }
  }
  div.h3 h3 {
    margin-bottom: 0;
    padding: 12px 20px 0;
    width: 642px;
    background: url(img/h3_bg.gif) no-repeat -1364px top;
    color: #ffffff;
    font-weight: bold;
    /* h3 background by jquery */
    font-size: $font_size_xl;
    @media only screen and (max-width: $sm) {
      background: none;
      width: auto;
      padding: 0;
    }
  }
}

.join .experience table.price {
  float: none;
  width: auto;
  margin: 0 0 40px 18px;
  th {
    background: #eaf4fb;
    border-bottom: solid 3px #fff;
    font-weight: normal;
    text-align: left;
    vertical-align: top;
    padding: 5px 10px;
  }
  td {
    vertical-align: top;
    border-bottom: solid 3px #fff;
    padding: 5px 10px;
  }
}

.join .regularcourse .option,
.join .programcourse .option {
  div.h4 {
    overflow: hidden;
    margin-bottom: 20px;
    padding-bottom: 8px;
    background: url(img/option_timeline_h4_bg.gif) no-repeat 0 bottom;
    @media only screen and (max-width: $sm) {
      padding: 0.3em 0.5em;
      background: none;
      background-color: $yellow_green_brilliant_2;
    }
  }
  div.h4 h4 {
    float: left;
    margin: 0;
    padding: 10px 18px 0;
    width: 298px;
    background: url(img/option_timeline_h4_bg.gif) no-repeat 0 top;
    color: #fff;
    font-weight: bold;
    font-size: $font_size_xl;
    line-height: 1.4;
    @media only screen and (max-width: $sm) {
      padding: 0;
      width: auto;
      background: none;
      font-weight: normal;
    }
  }
  h5.tit {
    border: solid 1px $yellow_green_brilliant_2;
    font-size: $font_size_xl;
    font-weight: bold;
    line-height: 1;
    color: #9db722;
    width: 278px;
    margin: 0 auto 15px;
    padding: 8px 10px;
  }
  dl {
    line-height: 1.6;
    width: 298px;
    margin: 0 auto 20px;
  }
  dt {
    background: url(img/bullet_ygreen01.gif) no-repeat 3px 5px;
    font-size: $font_size_xl;
    color: #9db722;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 5px;
    padding-left: 23px;
  }
  dt span {
    font-size: $font_size_base;
    width: auto;
    display: block;
  }
  dd {
    background: url(img/bullet_ygreen01.gif) no-repeat 3px 4px;
    margin-bottom: 10px;
    padding-left: 23px;
  }
}

// .join .regularcourse .option h4,
// .join .programcourse .option h4 {    /* for off javascript */
//     background:$yellow_green_brilliant_2;
//     font-size: $font_size_xl;
//     font-weight:bold;
//     line-height:1.4;
//     color:#ffffff;
//     width:298px;
//     float:left;
//     margin:0;
//     padding:10px 18px;
// }

// .join .regularcourse .option div.h4,
// .join .programcourse .option div.h4 {    /* h3 background by jquery */
//     background:url(img/option_timeline_h4_bg.gif) no-repeat 0 bottom;
//     margin-bottom:20px;
//     padding-bottom:8px;
//     overflow:hidden;
// }
// .join .regularcourse .option div.h4 h4,
// .join .programcourse .option div.h4 h4 {    /* h3 background by jquery */
//     background:url(img/option_timeline_h4_bg.gif) no-repeat 0 top;
//     padding-bottom:0;
// }
// .join .regularcourse .option h5.tit,
// .join .programcourse .option h5.tit {
//     border:solid 1px $yellow_green_brilliant_2;
//     font-size: $font_size_xl;
//     font-weight:bold;
//     line-height:1;
//     color:#9DB722;
//     width:278px;
//     margin:0 auto 15px;
//     padding:8px 10px;
// }
// .join .regularcourse .option dl,
// .join .programcourse .option dl {
//     line-height:1.6;
//     width:298px;
//     margin:0 auto 20px;
// }
// .join .regularcourse .option dt,
// .join .programcourse .option dt {
//     background:url(img/bullet_ygreen01.gif) no-repeat 3px 5px;
//     font-size: $font_size_xl;
//     color:#9DB722;
//     font-weight:bold;
//     line-height:1.2;
//     margin-bottom:5px;
//     padding-left:23px;
// }
// .join .regularcourse .option dt span,
// .join .programcourse .option dt span {
//     font-size: $font_size_base;
//     width:auto;
//     display:block;
// }
// .join .regularcourse .option dd,
// .join .programcourse .option dd {
//     background:url(img/bullet_ygreen01.gif) no-repeat 3px 4px;
//     margin-bottom:10px;
//     padding-left:23px;
// }
//

.join .regularcourse .option,
.join .programcourse .option {
  dt + dd {
    background: none;
    margin-bottom: 15px;
  }
  dd dl {
    width: 288px;
    margin-left: 10px;
    @media only screen and (max-width: $sm) {
      width: auto;
      margin-left: 0;
    }
  }
  dd dt,
  dd dd {
    background: url(img/bullet_ygreen02.gif) no-repeat 3px 5px;
    padding-left: 23px;
  }
  dd dt + dd {
    background: none;
  }
}

// .join .regularcourse .option dt+dd,
// .join .programcourse .option dt+dd {
//     background:none;
//     margin-bottom:15px;
// }
// .join .regularcourse .option dd dl,
// .join .programcourse .option dd dl {
//     width:288px;
//     margin-left:10px;
// }
// .join .regularcourse .option dd dt,
// .join .regularcourse .option dd dd,
// .join .programcourse .option dd dt,
// .join .programcourse .option dd dd {
//     background:url(img/bullet_ygreen02.gif) no-repeat 3px 5px;
//     padding-left:23px;
// }
// .join .regularcourse .option dd dt+dd,
// .join .programcourse .option dd dt+dd {
//     background:none;
// }

.join .regularcourse .timeline,
.join .programcourse .timeline {
  div.h4 {
    overflow: hidden;
    margin-bottom: 20px;
    padding-bottom: 8px;
    /* h4 background by jquery */
    background: url(img/option_timeline_h4_bg.gif) no-repeat -334px bottom;
    @media only screen and (max-width: $sm) {
      background: none;
      background-color: #8ec2e3;
      padding: 0.3em 0.5em;
    }
  }
  div.h4 h4 {
    float: left;
    margin: 0;
    padding: 10px 18px 0;
    width: 298px;
    /* h4 background by jquery */
    background: url(img/option_timeline_h4_bg.gif) no-repeat -334px top;
    color: #ffffff;
    font-weight: bold;
    font-size: $font_size_xl;
    line-height: 1.4;
    @media only screen and (max-width: $sm) {
      background: none;
      padding: 0;
      font-weight: normal;
    }
  }
  dl {
    margin: 0 auto 20px;
    width: 298px;
    line-height: 1.6;
  }
  dt {
    margin-bottom: 10px;
    color: #4b99d2;
    font-weight: bold;
    font-size: $font_size_xl;
    line-height: 1.2;
  }
  dd {
    margin-bottom: 15px;
    background: none;
  }
}

// .join .regularcourse .timeline h4,
// .join .programcourse .timeline h4 {    /* for off javascript */
//     background:#8ec2e3;
//     font-size: $font_size_xl;
//     font-weight:bold;
//     line-height:1.4;
//     color:#ffffff;
//     width:298px;
//     float:left;
//     margin:0;
//     padding:10px 18px;
// }
// .join .regularcourse .timeline div.h4,
// .join .programcourse .timeline div.h4 {    /* h4 background by jquery */
//     background:url(img/option_timeline_h4_bg.gif) no-repeat -334px bottom;
//     margin-bottom:20px;
//     padding-bottom:8px;
//     overflow:hidden;
// }
// .join .regularcourse .timeline div.h4 h4,
// .join .programcourse .timeline div.h4 h4 {    /* h4 background by jquery */
//     background:url(img/option_timeline_h4_bg.gif) no-repeat -334px top;
//     padding-bottom:0;
// }

// .join .regularcourse .timeline dl,
// .join .programcourse .timeline dl {
//     line-height:1.6;
//     width:298px;
//     margin:0 auto 20px;
// }
// .join .regularcourse .timeline dt,
// .join .programcourse .timeline dt {
//     font-size: $font_size_xl;
//     color:#4B99D2;
//     font-weight:bold;
//     line-height:1.2;
//     margin-bottom:10px;
// }
// .join .regularcourse .timeline dd,
// .join .programcourse .timeline dd {
//     background:none;
//     margin-bottom:15px;
// }
.join .spotcourse .option h5.tit {
  border: solid 1px $yellow_green_brilliant_2;
  font-size: $font_size_xl;
  font-weight: bold;
  line-height: 1;
  color: #9db722;
  width: 646px;
  margin: 0 auto 15px;
  padding: 8px 10px;
}
.join .spotcourse .option dl,
.join .spotcourse .option .option__supplement {
  line-height: 1.8;
  width: 646px;
  margin: 0 auto 20px;
}
.join .spotcourse .option dt {
  background: url(img/bullet_ygreen01.gif) no-repeat 3px 5px;
  font-size: $font_size_xl;
  color: #9db722;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 5px;
  padding-left: 23px;
}
.join .spotcourse .option dt span {
  font-size: $font_size_base;
  width: auto;
  display: block;
}
.join .spotcourse .option dd {
  background: url(img/bullet_ygreen01.gif) no-repeat 3px 8px;
  margin-bottom: 10px;
  padding-left: 23px;
}
.join .spotcourse .option dt + dd {
  background: none;
  margin-bottom: 15px;
}
.join .spotcourse .option dd dl {
  width: 288px;
  margin-left: 10px;
}
.join .spotcourse .option dd dt,
.join .spotcourse .option dd dd {
  background: url(img/bullet_ygreen02.gif) no-repeat 3px 5px;
  padding-left: 23px;
}
.join .spotcourse .option dd dt + dd {
  background: none;
}
.join .regularcourse .option,
.join .programcourse .option {
  dl {
    line-height: 1.6;
    width: 298px;
    margin: 0 auto 20px;
    @media only screen and (max-width: $sm) {
      width: auto;
    }
  }
  dt {
    background: url(img/bullet_ygreen01.gif) no-repeat 3px 5px;
    font-size: $font_size_xl;
    color: #9db722;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 5px;
    padding-left: 23px;
  }
}

.join .specialcourse .timeline {
  dl {
    line-height: 1.6;
    width: 298px;
    margin: 0 0 20px 18px;
    float: left;
    @media only screen and (max-width: $sm) {
      width: auto;
    }
  }
  dt {
    font-size: $font_size_xl;
    color: #ac83d6;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 5px;
  }
  dd + dt {
    margin-top: 20px;
  }
}

.flow {
  h4 {
    font-size: $font_size_xl;
    color: #6b992c;
    font-weight: bold;
    margin: 0 0 10px 18px;
    @media only screen and (max-width: $sm) {
      margin: 0 !important;
    }
  }
  @media only screen and (max-width: $sm) {
    .step1 h4:before {
      content: "1";
    }
    .step2 h4:before {
      content: "2";
    }
    .step3 h4:before {
      content: "3";
    }
  }
}

.flow {
  .step1,
  .step2,
  .step3 {
    min-height: 155px;
    h4 {
      height: 37px;
      margin: 0 0 10px 18px;
      padding: 15px 0 0 60px;
      @media only screen and (max-width: $sm) {
        position: relative;
        padding: 0.25em 0 0.5em 2em;
        width: auto;
        height: auto;
        background: none;
        margin: 0 0 1em;
        &:before {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          padding-top: 0.1em;
          width: 1.5em;
          height: 1.4em;
          border-radius: 1em;
          background: $yellow_green_brilliant_1;
          color: #fff;
          line-height: 1;

          align-items: center;
          justify-content: center;
        }
      }
    }
    p {
      background: url(img/flow_arrow.gif) no-repeat 8px bottom;
      line-height: 1.6;
      width: 460px;
      margin: 0 0 0 18px;
      padding-bottom: 38px;
      @media only screen and (max-width: $sm) {
        background-position: center bottom;
      }
    }
  }
  @media only screen and (max-width: $sm) {
    .step1,
    .step2,
    .sptep3 {
      margin-bottom: 1em !important;
    }
    .step1 p {
      padding-bottom: 185px;
      margin-bottom: 1em !important;
    }
    .step2 p {
      padding-bottom: 185px;
      margin-bottom: 1em !important;
    }
    .step3 p {
      padding-bottom: 190px;
    }
  }
  .step3 p {
    background: none;
  }
}

.contents_wrap .terms {
  div.h3 {
    /* h3 background by jquery */
    background: none;
    margin-bottom: 0px;
    padding-bottom: 10px;
  }
  div.h3 h3 {
    /* h3 background by jquery */
    background: none; /* for off javascript */
    font-size: $font_size_xl;
    font-weight: bold;
    color: #000;
    width: 642px;
    padding: 12px 20px;
    margin-bottom: 20px;
    background: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

/*--disaster_prevention--*/
.contents_wrap .disaster_prevention h3 {
  background: none; /* for off javascript */
  font-size: $font_size_xl;
  font-weight: bold;
  color: #000000;
  width: 642px;
  padding: 12px 20px;
  margin-bottom: 20px;
}
.contents_wrap .disaster_prevention div.h3 {
  /* h3 background by jquery */
  background: none;
  margin-bottom: 0px;
  padding-bottom: 10px;
}
.contents_wrap .disaster_prevention div.h3 h3 {
  /* h3 background by jquery */
  background: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.program p.summary {
  background: url(img/program_summary_illust.gif) no-repeat right;
  width: 366px;
  padding: 0 280px 0 0;
}
.program .program_list + p.summary {
  background: url(img/program_summary_illust2.gif) no-repeat right;
}
.day_btn li {
  margin-right: 12px;
  float: left;
}
.day_btn li a {
  display: block;
  width: 36px;
  overflow: hidden;
}
.contents .day_btn li img {
  margin: 0;
}
.contents .day_btn li.day_tue img {
  margin-left: -48px;
}
.contents .day_btn li.day_wed img {
  margin-left: -96px;
}
.contents .day_btn li.day_thu img {
  margin-left: -144px;
}
.contents .day_btn li.day_fri img {
  margin-left: -192px;
}
.contents .day_btn li a:hover img {
  margin-top: -36px;
}
.contents .day_btn li a.select img {
  margin-top: -72px;
}
* html .day_btn li a:hover {
  zoom: 1; /* for IE6 */
}
.program_description h4 {
  font-size: $font_size_xxl;
  color: #ea5350;
  width: 646px;
  margin: 0 auto 5px;
}
.teacher h5 {
  font-size: $font_size_sm;
  color: #ea5350;
  width: 510px;
  margin: 0 18px 5px 0;
  float: right;
}
.teacher h5 strong {
  font-size: $font_size_xl;
  font-weight: normal;
  margin-top: 3px;
  display: block;
}
.teacher h5 + p {
  width: 510px;
  margin: 0 18px 15px 0;
  float: right;
}

.communication_text {
  // .communication_text {
  //     width:646px;
  //     margin:0 auto;
  // }
  .date,
  h3,
  .box {
    width: 646px;
    margin: 0 auto;
    @media only screen and (max-width: $sm) {
      width: auto;
    }
  }
  .date {
    font-size: $font_size_base;
    margin-bottom: 10px;
  }
  h3 {
    background: none;
    font-size: $font_size_xl;
    color: #000000;
    font-weight: bold;
    margin-bottom: 20px;
    padding: 0;
  }
}

.voice h4 {
  font-size: $font_size_xl;
  width: 646px;
  margin: 0 auto 10px;
}
.voice p {
  background: url(img/line_green.gif) repeat-x bottom;
  margin-bottom: 20px;
  padding-bottom: 20px;
  overflow: hidden;
}
.voice strong {
  font-weight: normal;
  text-align: right;
  width: 646px;
  display: block;
  margin: 0 auto;
}

.recruit table {
  border-collapse: collapse;
  border-top: 1px dotted #7aa335;
  line-height: 1;
  margin: 0 0 20px 18px;
}
.recruit table td {
  border-bottom: 1px dotted #7aa335;
  padding: 10px 10px 8px;
}
.recruit h4 {
  border-top: 1px dotted #7aa335;
  border-bottom: 1px dotted #7aa335;
  font-size: $font_size_xl;
  color: #6e9d2e;
  font-weight: normal;
  width: 642px;
  margin: 80px auto 5px;
  padding: 5px 20px;
}
.recruit h4 + table,
.recruit h4 + table td {
  border: none;
}

.sitemap .sitemap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media only screen and (max-width: $sm) {
    display: block;
  }
  dl {
    margin: 0 0 30px 20px;
    width: 30%;
    @media only screen and (max-width: $sm) {
      width: auto;
    }
  }
  dt {
    margin-bottom: 1em;
    padding: 0 0 0 1.2em;
    background: url(img/bullet_ygreen01.gif) no-repeat left;
    font-size: $font_size_xl;
    line-height: 1.4;
  }
  dd {
    margin: 0 0 1em 2em;
    padding: 0 0 0 1.5em;
    background: url(img/bullet_ygreen02.gif) no-repeat left;
    line-height: 1.4;
  }
  dt + dt {
    margin-top: 25px;
  }
}

.privacy h4 {
  font-size: $font_size_xl;
  width: 646px;
  margin: 0 auto 30px;
  @media only screen and (max-width: $sm) {
    width: auto;
  }
}
dl.gallery-item {
  width: auto;
  float: left;
}

/*--faq--*/
.faq {
  h4 {
    background: url(img/faq_bullet_q.gif) no-repeat left 3px;
    color: #ea5350;
    width: 596px;
    margin: 0 auto;
    padding: 13px 0 10px 50px;
    @media only screen and (max-width: $sm) {
      width: auto;
    }
  }
  p {
    background: url(img/faq_bullet_a.gif) no-repeat left 3px;
    width: 596px;
    padding: 10px 0 13px 50px;
  }
}

/*--category top--*/
.category dl {
  width: 646px;
  margin: 0 auto;
}
.category .list dt {
  background: url("img/bullet_ygreen01.gif") no-repeat scroll left center
    transparent;
  font-size: $font_size_xl;
  margin-bottom: 10px;
  padding: 0 0 0 24px;
}
