/*--------------------
header
--------------------*/
header {
  overflow: hidden;
  margin: 0 auto;
  padding: 12px 0 6px;
  width: 960px;
  @media only screen and (max-width: $sm) {
    width: auto;
    padding: 12px $gutter-half 4px;
  }
}

.header_info .txt {
  display: block;
  overflow: hidden;
}
.logo {
  float: left;
  margin-right: 21px;
  margin-left: 8px;
  height: 73px;
  @media only screen and (max-width: $sm) {
    height: auto;
    margin-left: 0;
  }
  img {
    width: 358px;
    @media only screen and (max-width: $sm) {
      width: 200px;
    }
  }
}

.catch {
  float: left;
  overflow: hidden;
  margin: 22px 0 0;
  padding: 7px 3px 6px;
  max-width: 330px;
  max-height: 34px;
  width: auto;
  border-top: 1px dotted;
  border-bottom: 1px dotted;
  font-size: 12px;
  line-height: 1.4;
  @media only screen and (max-width: $sm) {
    display: none;
  }
}
.header_info {
  display: block;
  float: right;
  padding-top: 20px;
  width: 222px;
  height: 95px;
  background: url(img/head_info.gif) no-repeat;
  text-align: center;
  @media only screen and (max-width: $sm) {
    display: none;
  }
}
.header_info .txt {
  height: 58px;
}
.header_info .txt img {
  margin-left: 4px;
}
.header_info a {
  display: block;
  color: #3f1f00;
  font-weight: bold;
  font-size: 12px;
}
